import React, { SyntheticEvent } from 'react';
import _ from 'lodash';
import { generateQR } from '../lib/Certificates'
import {
    Table, Radio, Panel, Navbar, Nav, Input, Form, FormGroup,
    ControlLabel, FormControl, Checkbox, Icon, Modal,
    Button, HelpBlock, Loader, IconButton, Pagination, SelectPicker,
} from 'rsuite';
import revokeIcon from '../img/revoke_icon.png';

import './CertificateAuthorityList.less';
import { NodeService } from '../services/NodeService';
import { CertificateInfo, CANodeInfo, HolderInfo, CertificatesInfo, CurriculumSummary } from '../services/ModelView';
import HolderCertificates from '../lib/HolderCertificates';
import { Certificate } from '../lib/Certificates';
import { SearchCertificate } from '../lib/SearchCertificate';
import { CrewmanService } from '../services/CrewmanService';


// =======================================================================================================
interface RadioCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    selectedKey: string | null;
    dataKey: string
}
const RadioCell = (props: RadioCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            <Radio
                value={props.rowData[props.dataKey]}
                inline
                onChange={props.onChange}
                checked={props.selectedKey === props.rowData[props.dataKey]}
            />
        </div>
    </Table.Cell>
}

const HelpBlockStar = () => {
    return <span className="rs-help-block rs-help-block-tooltip"><i className="rs-icon rs-icon-star"></i></span>
}
interface CheckboxCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    dataKey: string
}
const CheckboxCell = (props: CheckboxCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            <Checkbox
                value={props.rowData[props.dataKey]}
                inline
                onChange={props.onChange}
                checked={props.rowData[props.dataKey]}
            />
        </div>
    </Table.Cell>
}

interface DisabledCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    dataKey: string
}
const DisabledCell = (props: CheckboxCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            {
                (props.rowData[props.dataKey]) ?
                    <Icon
                        style={{ color: 'red' }}
                        icon='minus-circle'
                    /> :
                    null
            }
        </div>
    </Table.Cell>
}
// ------------------------------------------------------------------------------------------------------
export enum CAModalModes {
    Processing,
    AddCA,
    Error,
    CreateRightsRevoke,
    CreateRightsApproved,
    ChargeETH,
    CertRevoke,
    Holder,
    SearchCertificates
}
export interface ModalCAAdd {
    address: string;
    email: string;
    name: string;
    organization: string
}
export interface ModalChargeEth {
    amount: number;
}
export interface ModalError {
    message: string
}
export type ModalData = ModalCAAdd | ModalError | ModalChargeEth
// ------------------------------------------------------------------------------------------------------
export interface CertificateAuthorityProps {
    height: number;
}
// ------------------------------------------------------------------------------------------------------
export interface CertificateAuthorityState {
    selectedNodeKey: string | null;
    activePage: number
    totalCertificates: number
    selectedCertificateKey: string | null;
    selectedCertificateInfo: Partial<CertificateInfo> | undefined;
    selectedCertificateImage: string | undefined;
    selectedCertificateCurriculum: CurriculumSummary | undefined;
    selectedNodeInfo: CANodeInfo | undefined;
    searchCertificateKey: string | null;
    sortNodeCertsColumn: string | undefined;
    sortNodeCertsType: "desc" | "asc" | undefined;
    caNodes: CANodeInfo[];
    searchDataKey: string | null;
    sortDataColumn: string | undefined;
    sortDataType: "desc" | "asc" | undefined;
    showModal?: CAModalModes;
    modalBackdrop: 'static' | true | false;
    modalData?: ModalData;
    gb: string;
    balance: number;
    holderChallenge: string;
    loadingCACerts: boolean;
}
// ------------------------------------------------------------------------------------------------------
export default class CertificateAuthority extends React.Component<CertificateAuthorityProps, CertificateAuthorityState> {
    private NodeService: NodeService;
    constructor(props: CertificateAuthorityProps) {
        super(props);


        this.state = {
            selectedNodeKey: null,
            selectedCertificateKey: null,
            selectedCertificateInfo: undefined,
            selectedCertificateImage: undefined,
            selectedCertificateCurriculum: undefined,
            searchDataKey: null,
            selectedNodeInfo: undefined,
            searchCertificateKey: null,
            sortDataType: undefined,
            sortDataColumn: undefined,
            sortNodeCertsColumn: undefined,
            sortNodeCertsType: undefined,
            caNodes: [],
            showModal: CAModalModes.Processing,
            modalData: undefined,
            modalBackdrop: 'static',
            balance: 0,
            gb: '',
            holderChallenge: "",
            activePage: 1,
            totalCertificates: 0,
            loadingCACerts: false,
        };
        this.NodeService = new NodeService();
    }
    // -------------------------------------------------------------------------------------------------



    async getCACertificates(caAddress: string, page: number) {
        let certs: CertificateInfo[] = [];
        this.setState({ loadingCACerts: true })
        // Get the list with certificates
        try {
            let certificates = await this.NodeService.gbGetCaCertificates(caAddress, page, 5);
            // Get the information of the certificates
            this.setState({ totalCertificates: certificates.totalCount, loadingCACerts: false })
            return certificates.result;
        } catch (ex) {
            console.error(ex);
            return certs;
        }
    }

    // --------------------------------------------------------------------------------------------------

    async getCANodes() {
        let caList: CANodeInfo[] = [];
        caList = await this.NodeService.gbGetCaInfo();
        // Update data
        this.setState({ caNodes: caList, balance: 0, showModal: undefined })
    }
    // --------------------------------------------------------------------------------------------------
    async componentDidMount() {
        let gbAddress = await this.NodeService.getAddress()
        this.setState({ gb: gbAddress.publicKey });
        this.getCANodes();
    }
    // --------------------------------------------------------------------------------------------------
    handleSearchCertificateChange = (value: string, event: SyntheticEvent<HTMLElement, Event>) => this.setState({ searchCertificateKey: value })
    // --------------------------------------------------------------------------------------------------
    handleSearchChange = (value: string, event: SyntheticEvent<HTMLElement, Event>) => this.setState({ searchDataKey: value })
    // --------------------------------------------------------------------------------------------------
    handleCertificateRowSelect = async (value: any, checked: boolean) => {
        var img = await generateQR(value);
        var certInfo = _.find(this.state.selectedNodeInfo?.certs, x => x.address === value)
        var lines = await CrewmanService.getCertificateInfo(certInfo?.type)
        this.setState({
            selectedCertificateKey: value,
            selectedCertificateImage: img,
            selectedCertificateInfo: certInfo,
            selectedCertificateCurriculum: lines
        });
    }
    // --------------------------------------------------------------------------------------------------
    handleRowSelect = async (value: any, checked: boolean) => {
        this.setState({ selectedNodeKey: null })
        let data = await this.getCACertificates(value, 1);
        let ca = _.find(this.state.caNodes, x => x.ca === value);
        if (ca !== undefined)
            ca.certs = data;
        this.setState({
            activePage: 1,
            selectedNodeKey: value,
            selectedNodeInfo: ca,
            selectedCertificateKey: null,
            selectedCertificateInfo: undefined,
        });
    }
    // --------------------------------------------------------------------------------------------------
    onSortCertsColumn = (sortColumn: string, sortType: "desc" | "asc") => {
        this.setState((s: CertificateAuthorityState) => {
            if (s.selectedNodeInfo != null) {
                s.sortNodeCertsColumn = sortColumn;
                s.sortNodeCertsType = sortType;
                s.selectedNodeInfo.certs = _.orderBy(this.state.selectedNodeInfo?.certs, x => _.get(x, sortColumn), sortType);
            }
            return s;
        })
    }
    // --------------------------------------------------------------------------------------------------
    renderCertificatePanel() {
        if (this.state.selectedNodeKey == null ||
            this.state.selectedCertificateKey == null ||
            this.state.selectedCertificateInfo == null)
            return <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
            </Navbar>;


        return <>
            <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        {
                            (!this.state.selectedCertificateInfo.revoked) ? null :
                                <Nav.Item icon={<img src={revokeIcon} style={{ width: 25, height: 25 }} />} > Revoked</Nav.Item>
                        }
                    </Nav>
                </Navbar.Body>
            </Navbar>
            <Certificate
                width="100%"
                height={this.props.height - 56 - 20}
                certInfo={this.state.selectedCertificateInfo}
                curriculumSummary={this.state.selectedCertificateCurriculum}
                img={this.state.selectedCertificateImage}
                showHolderInfo={() => this.setState({ showModal: CAModalModes.Holder })}
            />
        </>
    }
    // --------------------------------------------------------------------------------------------------

    handleSelect = async (eventKey: number) => {
        this.setState({ activePage: eventKey })
        var certs = await this.getCACertificates(this.state.selectedNodeKey!, eventKey)
        let ca = _.find(this.state.caNodes, x => x.ca === this.state.selectedNodeKey);
        if (ca !== undefined)
            ca.certs = certs;
        this.setState({ selectedNodeInfo: ca })
    }

    complexSearch = () => {
        this.setState({ showModal: CAModalModes.SearchCertificates })
    }

    refreshCACertificates = async () => {
        var data = await this.getCACertificates(this.state.selectedNodeKey!, 1);
        let ca = _.find(this.state.caNodes, x => x.ca === this.state.selectedNodeKey);
        if (ca !== undefined)
            ca.certs = data;
        this.setState({ selectedNodeInfo: ca })

    }
    // --------------------------------------------------------------------------------------------------
    renderCAPanel() {
        if (this.state.selectedNodeKey == null ||
            this.state.selectedNodeInfo == null)
            return <div><Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate Authority</div>
                </Navbar.Header>
            </Navbar>
                {this.state.loadingCACerts ? <Panel> <Loader></Loader></Panel> : null}
            </div>

        let caInfo = _.find(this.state.caNodes, d => d.ca === this.state.selectedNodeKey);
        let heightBody = this.props.height - 20;
        let heightCA = Math.floor(heightBody / 2) - 10;
        if (caInfo == null)
            return;

        // Filter certificated searching title/holder/seral_number
        let data = this.state.selectedNodeInfo.certs;
        //let data =  this.getCACertificates(this.state.selectedNodeKey).
        if (this.state.searchCertificateKey != null) {
            let searchStr = new RegExp(this.state.searchCertificateKey, 'i');
            data = _.filter(data, d => {
                if ((d.title?.search(searchStr) ?? -1) > -1)
                    return true;
                if ((d.holder?.search(searchStr) ?? -1) > -1)
                    return true;
                return false;
            });
        }

        return <>
            <Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate Authority</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        {/*<Nav.Item icon={<i style={{ paddingRight: 10, fontSize: "large" }} className="fab fa-ethereum"></i>} onClick={() => this.setState({ showModal: CAModalModes.ChargeETH })}  >Charge ETH</Nav.Item> */}
                        {
                            (caInfo.createRights) ?
                                <Nav.Item icon={<img src={revokeIcon} style={{ width: 25, height: 25 }} />} onClick={() => this.setState({ showModal: CAModalModes.CreateRightsRevoke })}  > Revoke</Nav.Item> :
                                <Nav.Item icon={<Icon icon="check-circle" />} onClick={() => this.setState({ showModal: CAModalModes.CreateRightsApproved })} > Approve</Nav.Item>
                        }
                    </Nav>
                </Navbar.Body>
            </Navbar>

            <Form formValue={this.state.selectedNodeInfo} layout="horizontal" style={{ margin: 10 }}>
                <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl name="ca" plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl name="name" plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>CreateRight</ControlLabel>
                    <FormControl name="createRights" plaintext={true}
                        accepter={Checkbox as any}
                        checked={this.state.selectedNodeInfo.createRights}
                    />
                </FormGroup>
            </Form>
            <Panel shaded bodyFill style={{ margin: 10 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Certificates</div>
                    </Navbar.Header>
                    <Navbar.Body>
                        <IconButton onClick={(c) => this.refreshCACertificates()} style={{ marginTop: 12 }} icon={<Icon icon="refresh" />}>
                        </IconButton>

                    </Navbar.Body>
                </Navbar>
                {this.state.loadingCACerts ? <Loader></Loader> : <div>
                    <Table rowHeight={46} data={data}
                        height={heightCA - 120}
                    >

                        <Table.Column width={50} align="center" >
                            <Table.HeaderCell></Table.HeaderCell>
                            <RadioCell
                                dataKey="address"
                                selectedKey={this.state.selectedCertificateKey}
                                onChange={this.handleCertificateRowSelect}
                            />
                        </Table.Column>

                        <Table.Column flexGrow={1} align="center" >
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.Cell dataKey="title" />
                        </Table.Column>

                        <Table.Column flexGrow={1} align="center" >
                            <Table.HeaderCell>Address</Table.HeaderCell>
                            <Table.Cell dataKey="address" />
                        </Table.Column>

                        <Table.Column flexGrow={1} align="center" >
                            <Table.HeaderCell>Holder</Table.HeaderCell>
                            <Table.Cell dataKey="holderName" />
                        </Table.Column>

                        <Table.Column flexGrow={1} align="center" >
                            <Table.HeaderCell>Revoked</Table.HeaderCell>
                            <DisabledCell
                                dataKey="revoked"
                            />
                        </Table.Column>
                        <Table.Column flexGrow={1} align="center" >
                            <Table.HeaderCell>Valid</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData: CertificateInfo) => {
                                    return (<div>
                                        {!rowData.valid ? <Icon
                                            style={{ color: 'red' }}
                                            icon='minus-circle'
                                        /> : <Icon
                                            style={{ color: 'green' }}
                                            icon='check-circle'
                                        />}
                                    </div>)
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                    <Pagination
                        prev
                        last
                        next
                        first
                        maxButtons={6}
                        size="sm"
                        boundaryLinks={true}
                        pages={Math.ceil(this.state.totalCertificates / 5)}
                        activePage={this.state.activePage}
                        onSelect={this.handleSelect}
                    />
                </div>}
            </Panel>
        </>
    }
    // --------------------------------------------------------------------------------------------------
    onSortDataColumn = (sortColumn: string, sortType: "desc" | "asc") => {
        this.setState({
            caNodes: _.orderBy(this.state.caNodes, x => _.get(x, sortColumn), sortType),
            sortDataColumn: sortColumn,
            sortDataType: sortType
        })
    }

    // --------------------------------------------------------------------------------------------------
    modalCreateRightsRevoke = async () => {
        if (this.state.selectedNodeKey == null)
            return;
        this.setState({ showModal: CAModalModes.Processing });
        let response = await this.NodeService.gbCaRevokeCreate(this.state.selectedNodeKey);
        if (response.code !== 0)
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to revoke create rights. " + response.message.reason } })
        else
            this.closeModal();
        this.getCANodes();
    }
    // --------------------------------------------------------------------------------------------------
    modalCreateRightsApproved = async () => {
        if (this.state.selectedNodeKey == null)
            return;
        this.setState({ showModal: CAModalModes.Processing });
        let response = await this.NodeService.gbCaApproveCreate(this.state.selectedNodeKey);
        if (response.code !== 0)
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to approved create rights. " + response.message.reason } })
        else
            this.closeModal();
        this.getCANodes();
    }
    // --------------------------------------------------------------------------------------------------
    modalRevokeCertificate = async () => {
        if (this.state.selectedCertificateKey == null)
            return;
        this.setState({ showModal: CAModalModes.Processing });
        let response = await this.NodeService.revokeCertificate(this.state.selectedCertificateKey);
        if (response.code !== 0)
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to revoke the certificate. " + response.message.reason } })
        else
            this.closeModal();
        this.getCANodes();
    }
    // --------------------------------------------------------------------------------------------------
    modalSendEther = async () => {
        if (this.state.modalData == null ||
            this.state.selectedNodeKey == null)
            return;
        let data: ModalChargeEth = this.state.modalData as ModalChargeEth;
        try {
            this.setState({ showModal: CAModalModes.Processing });
            let response = await this.NodeService.gbSendEther(this.state.selectedNodeKey, data.amount);
            if (response.code !== 0) {
                this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to charge:" + response.message.reason } })
            } else
                this.closeModal();
        } catch (ex) {
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to make the request. Code: " + ex.message } });
        }
        this.getCANodes();
    }
    // --------------------------------------------------------------------------------------------------
    modalCAAddHandle = async () => {
        if (this.state.modalData == null)
            return;
        let data: ModalCAAdd = this.state.modalData as ModalCAAdd;

        try {
            if (data.email === undefined || data.email === null || data.email.length === 0)
                this.setState({ showModal: CAModalModes.Error, modalData: { message: "Email is mandatory" } });

            if (data.name === undefined || data.name === null || data.name.length === 0)
                this.setState({ showModal: CAModalModes.Error, modalData: { message: "CA Owner name is mandatory" } });
            if (data.organization === undefined || data.organization === null || data.organization.length === 0)
                this.setState({ showModal: CAModalModes.Error, modalData: { message: "Certificate Authority name is mandatory" } });
        } catch (e) {
            this.setState({ showModal: CAModalModes.Error, modalData: { message: e.message } });
            return;
        }

        try {
            this.setState({ showModal: CAModalModes.Processing });
            let response = await this.NodeService.gbAddCA(data.email, data.organization, data.name,);
            if (response.code !== 0) {
                this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to add: " + response.message } })
            }
            else {
                this.closeModal();
                this.getCANodes();
            }
        } catch (ex) {
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Failed to make the request. Code: " + ex.message } });
        }

    }
    // --------------------------------------------------------------------------------------------------
    closeModal = () => this.setState({ showModal: undefined, modalData: undefined })
    handleModalDataChange = (value: any) => this.setState({ modalData: value });
    // --------------------------------------------------------------------------------------------------
    handleSearchCertificatesResult = (r: boolean, v: CertificatesInfo | null) => {

        if (r) {

            var caInfo = this.state.selectedNodeInfo;
            if (v !== null && caInfo !== undefined) {
                caInfo.certs = v.result;
                caInfo.certsLength = v.result.length;
                this.setState({ activePage: 1, totalCertificates: v.totalCount, selectedNodeInfo: caInfo, showModal: undefined })
            }
        }
        else {
            this.setState({ showModal: CAModalModes.Error, modalData: { message: "Unable to get certificates based on your query" } })
        }

    }

    // --------------------------------------------------------------------------------------------------

    renderModals() {

        switch (this.state.showModal) {
            // --------------------------------

            case CAModalModes.SearchCertificates:
                return <>
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <SearchCertificate onSearchComplete={( v) => this.handleSearchCertificatesResult( v)} ca={this.state.selectedNodeKey!}></SearchCertificate>*/}
                    </Modal.Body>
                </>
            // --------------------------------
            case CAModalModes.Processing:
                return <>
                    <Modal.Header>
                        <Modal.Title>Processing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center' }}>
                            <Loader size="md" />
                        </div>
                    </Modal.Body>
                </>


            // --------------------------------
            case CAModalModes.AddCA:
                return <>
                    <Modal.Header>
                        <Modal.Title>Add Certificate Authority</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            fluid
                            onChange={this.handleModalDataChange}
                            formValue={this.state.modalData}
                        >

                            <FormGroup>
                                <ControlLabel>Certifice Authority Name</ControlLabel>
                                <FormControl name="organization" />
                                <HelpBlockStar></HelpBlockStar>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>CA Owner Email</ControlLabel>
                                <FormControl name="email" />
                                <HelpBlock tooltip>Required</HelpBlock>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>CA Owner Name</ControlLabel>
                                <FormControl name="name" />
                                <HelpBlock tooltip>Required</HelpBlock>
                            </FormGroup>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalCAAddHandle} appearance="primary">
                            Add
                        </Button>
                        <Button onClick={this.closeModal} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </>
            // --------------------------------
            case CAModalModes.ChargeETH:
                return <>
                    <Modal.Header>
                        <Modal.Title>Charge ETH</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            fluid
                            onChange={this.handleModalDataChange}
                            formValue={this.state.modalData}
                        >
                            <FormGroup>
                                <ControlLabel>Amount (uETH)</ControlLabel>
                                <FormControl name="amount" type="number" />
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalSendEther} appearance="primary">
                            Charge
                        </Button>
                        <Button onClick={this.closeModal} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </>
            // --------------------------------
            case CAModalModes.Error: {
                let data: ModalError = this.state.modalData as ModalError;
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="warning"
                                size="3x"
                                style={{
                                    color: '#f04f43',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                {data.message}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} appearance="subtle">Close</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.CreateRightsRevoke: {
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="remind"
                                size="3x"
                                style={{
                                    color: '#ffb300',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                Revoke the create rights to {this.state.selectedNodeKey}.
                                <br />
                                Are you sure you want to proceed ?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalCreateRightsRevoke} appearance="primary">Yes</Button>
                        <Button onClick={this.closeModal} appearance="subtle">No</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.CreateRightsApproved: {
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="remind"
                                size="3x"
                                style={{
                                    color: '#ffb300',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                Approved the create rights to {this.state.selectedNodeKey}.
                                <br />
                                Are you sure you want to proceed ?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalCreateRightsApproved} appearance="primary">Yes</Button>
                        <Button onClick={this.closeModal} appearance="subtle">No</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.CertRevoke: {
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="remind"
                                size="3x"
                                style={{
                                    color: '#ffb300',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                Revoke the certificate to {this.state.selectedCertificateKey}.
                                <br />
                                Are you sure you want to proceed ?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalRevokeCertificate} appearance="primary">Yes</Button>
                        <Button onClick={this.closeModal} appearance="subtle">No</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.Holder: {
                let holderAddress = this.state.selectedCertificateInfo?.holder;
                let extra = JSON.parse(this.state.selectedCertificateInfo?.extra as string);
                var info: HolderInfo =
                {
                    address: holderAddress!,
                    firstName: extra["firstName"],
                    middleName: extra["middleName"],
                    lastName: extra["lastName"],
                    email: extra["email"],
                    date_of_birth: extra["dob"],
                    verified: false,
                    date_of_verification: new Date()

                };

                return <>
                    <Modal.Body>
                        <HolderCertificates
                            height={Math.ceil(this.props.height * 0.8)}
                            holderAddress={holderAddress}
                            holderInfo={info}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} appearance="primary">Close</Button>
                    </Modal.Footer>
                </>
            }

            // --------------------------------
        }

        return null;
    }
    // --------------------------------------------------------------------------------------------------
    render() {
        let data = _.filter(this.state.caNodes, d => this.state.searchDataKey == null || (d.name?.search(new RegExp(this.state.searchDataKey, 'i')) > -1));
        let heightBody = this.props.height - 20;
        let heightCA = Math.floor(heightBody / 2) - 10;
        return <div style={{ display: "flex" }} className="ca">
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Panel className="ca-list-panel" shaded bodyFill style={{ height: heightCA, margin: "10px 0px 0px 10px", overflow: "auto" }}>
                    <Navbar >
                        <Navbar.Header>
                            <div style={{
                                lineHeight: "56px",
                                paddingLeft: 20,
                                fontSize: "120%",
                                fontWeight: "bolder"
                            }} >GB ({this.state.gb})</div>
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav pullRight>
                                <Input style={{ width: 300, margin: 5 }} placeholder="Search..." onChange={this.handleSearchChange} />
                            </Nav>
                            <Nav pullRight>
                                <Nav.Item icon={<Icon icon="address-book-o" />} onClick={() => this.setState({ showModal: CAModalModes.AddCA })} >Add CA</Nav.Item>
                            </Nav>
                        </Navbar.Body>
                    </Navbar>




                    <Panel shaded bodyFill style={{ margin: 10 }}>
                        <Navbar appearance={"default"}>
                            <Navbar.Header>
                                <div style={{
                                    lineHeight: "56px",
                                    paddingLeft: 20,
                                    fontSize: "120%",
                                    fontWeight: "bolder"
                                }} >List of Certificate Authorities</div>
                            </Navbar.Header>
                        </Navbar>

                        <Table
                            rowHeight={46}
                            data={data}
                            sortColumn={this.state.sortDataColumn}
                            sortType={this.state.sortDataType}
                            onSortColumn={this.onSortDataColumn}
                            height={heightCA - 75}
                        >
                            <Table.Column width={50} align="center" >
                                <Table.HeaderCell></Table.HeaderCell>
                                <RadioCell
                                    dataKey="ca"
                                    selectedKey={this.state.selectedNodeKey}
                                    onChange={this.handleRowSelect}
                                />
                            </Table.Column>

                            <Table.Column flexGrow={1} align="center" sortable>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.Cell dataKey="name" />
                            </Table.Column>

                            <Table.Column flexGrow={1} align="center" sortable>
                                <Table.HeaderCell>#Certificates</Table.HeaderCell>
                                <Table.Cell dataKey="certsLength" />
                            </Table.Column>

                            <Table.Column flexGrow={1} align="center" sortable>
                                <Table.HeaderCell>Create Rights</Table.HeaderCell>
                                <CheckboxCell
                                    dataKey="createRights"
                                />
                            </Table.Column>
                        </Table>
                    </Panel>

                </Panel>
                <Panel shaded bodyFill style={{ height: Math.ceil(heightBody / 2), margin: "10px 0px 10px 10px", overflow: "auto" }}>
                    {this.renderCAPanel()}
                </Panel>
            </div>
            <Panel className="ca-certificate" shaded bodyFill style={{ height: heightBody, flex: 1, margin: 10, overflow: "auto" }}>
                {this.renderCertificatePanel()}
            </Panel>

            <Modal
                backdrop={this.state.modalBackdrop}
                className={(this.state.showModal === CAModalModes.Holder) ? "dialogHolder" : undefined}
                show={this.state.showModal != null}
                onHide={this.closeModal}
                overflow={false}
            >
                {this.renderModals()}
            </Modal>
        </div>
    }
}
// =======================================================================================================