import React from 'react';
import * as toastr from 'toastr';
import logo from '../logo.svg';
import mscLogo from '../img/weblogo@2x.png';

import {
    Container, Header, Content, Footer,
    Nav, Navbar, Icon, Dropdown
} from 'rsuite';


import CertificateAuthority from './CertificateAuthorityList';
import { AuthService } from '../services/AuthService';
import { NodeService } from '../services/NodeService';
import { Roles } from '../services/ModelView';

// =======================================================================================================
export interface AppProps {
    width: number;
    height: number;
}
// ------------------------------------------------------------------------------------------------------
export interface AppState {
    activeKey: AppPagesKeys;

    // User info
    user: any;
    userName: string | undefined;
}
// ------------------------------------------------------------------------------------------------------
enum AppPagesKeys {
    Home = "home",
    Validation = "Validation",
    GB = "gb"
}
// ------------------------------------------------------------------------------------------------------
export default class App extends React.Component<AppProps, AppState> {
    // --------------------------------------------------------------------------------------------------
    public authService: AuthService;
    // --------------------------------------------------------------------------------------------------
    constructor(props: AppProps) {
        super(props);

        this.state = {
            activeKey: AppPagesKeys.GB,
            user: null,
            userName: undefined,
        };
        this.authService = new AuthService();
        NodeService.setAuthService(this.authService);
    }
    // --------------------------------------------------------------------------------------------------
    public componentDidMount() {
        this.getUser();
    }
    // --------------------------------------------------------------------------------------------------
    public logout = () => this.authService.logout()
    // --------------------------------------------------------------------------------------------------

    public getUser = () => {
        this.authService
            .getUser()
            .then(user => {
                if (user) {

                    if (!user.profile?.role.includes(Roles.GBAdministrator.toString())) {
                        alert('You do not have permissions.');
                        return;
                    }
                    if (!user.expired) {
                        this.authService.currentUser = user;
                        console.log(user.access_token);
                    }
                } else {
                    toastr.error('You are not logged in.');

                    // Start login process
                    this.authService.login();
                }

                this.setState({ user: user, userName: user?.profile.name })
            })
            .catch(error => {
                console.error(error);
            })
    };
    // --------------------------------------------------------------------------------------------------
    renderDummyPage(text: string) {
        return <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p> GB {text} </p>
            </header>
        </div>
    }
    // --------------------------------------------------------------------------------------------------
    renderPage() {
        if (this.state.user == null)
            return null;

        switch (this.state.activeKey) {
            case AppPagesKeys.Home:
                return this.renderDummyPage("Home");

            case AppPagesKeys.Validation:
                return this.renderDummyPage("Validation");

            case AppPagesKeys.GB:
                return <CertificateAuthority height={this.props.height - 56 /* toolbar */ - 20 /* footer */} />;
        }
        return null;
    }
    // --------------------------------------------------------------------------------------------------
    handleSelect = (eventKey: any) => this.setState({ activeKey: eventKey })
    handleLogoutMenu = (eventKey: any, event: any) => this.authService.logout()
    // --------------------------------------------------------------------------------------------------
    render() {
        return <Container style={{ height: this.props.height, width: this.props.width }}>
            <Container>
                <Header>
                    <Navbar appearance="inverse" className="app-header">
                        <Navbar.Header>
                            <img src={mscLogo} className="msc-logo" alt="msc-logo" />
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect}>
                                {/* <Nav.Item eventKey={AppPagesKeys.Home} icon={<Icon icon="home" />}>Home</Nav.Item> */}
                                {/*<Nav.Item eventKey={AppPagesKeys.Validation} icon={<Icon icon="address-book" />}>Validation</Nav.Item> */}
                                <Nav.Item eventKey={AppPagesKeys.GB} icon={<Icon icon="certificate" />}>Governing Body</Nav.Item>
                            </Nav>
                            <Nav pullRight>
                                <Dropdown icon={<Icon icon="user-circle" />} trigger={["click", "hover"]} tile={this.state.userName}>
                                    <Dropdown.Item onSelect={this.handleLogoutMenu} icon={<Icon icon="exit" />} style={{ borderRadius: 0 }}>LogOut</Dropdown.Item>
                                </Dropdown>
                                <Nav.Item >{this.state.userName}</Nav.Item>
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                </Header>
                <Content className="app-body">
                    {this.renderPage()}
                </Content>
                <Footer>©  2020 Electi, AI, Blockchain, Cryptography. </Footer>
            </Container>
        </Container>
    }
}
// =======================================================================================================
